<!-- eslint-disable no-console -->
<script>
import appConfig from "@/app.config";
import api from "../../../common/axios";

/**
 * Forgot password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      error: null,
      tryingToReset: false,
      isResetError: false,
      config: {
        api: "/forgot_password",
      },
      model: {
        email: "",
      },
      responses: {
        message: null,
        variant: null,
      },
    };
  },
  methods: {
    fogotPassword() {
      let _ = this;
      api
        .post(_.config.api, _.model)
        .then((res) => {
          _.responses.message = res.data.message;
          _.responses.variant = "success";
          setTimeout(() => {
            _.$router.push("/login");
          }, 4000);
        })
        .catch((e) => {
          let vm = this;
          vm.responses.message = e.response.data.message;
          _.responses.variant = "danger";
        });
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="text-primary text-center px-4 pb-0 pt-4">
              <h1>Lupa Password</h1>
              <p class="text-black-50">
                Masukan alamat email Anda lalu Kami akan mengirimkan instruksi
                perubahan password
              </p>
            </div>
            <div class="card-body px-4 pt-0">
              <div class="p-3">
                <b-alert
                  show
                  :variant="responses.variant"
                  v-if="responses.message"
                >
                  {{ responses.message }}
                </b-alert>

                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(fogotPassword)"
                    class="form-horizontal mt-4"
                  >
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-form-group id="email-group" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="model.email"
                          type="email"
                          placeholder="Enter email"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row mb-0">
                      <div class="col-12">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Submit</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
